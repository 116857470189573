header#header {
  .header-wrapper {
    height: 0;
    position: relative;
    padding-bottom: 75%;

    @include media-breakpoint-up(md) {
      padding-bottom: 52%;
    }

    @include media-breakpoint-up(xl) {
      padding-bottom: 44%;
    }

    .header-bg {
      background: #ddd;
      background-size: cover;
      background-position: center center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .header-caption-wrapper {
      width: 100%;
      height: 100%;
      position: relative;
    }

    .header-caption {
      background: white;
      position: absolute;
      bottom: 10%;
      left: 0;
      font-family: $headings-font-family;
      font-weight: $headings-font-weight;
      padding: rem(12px 30px);
      border-bottom: 4px solid $secondary;
      font-size: $h4-font-size;
      color: $primary;

      @include media-breakpoint-up(md) {
        border-bottom: 7px solid $secondary;
      }
    }

  }
}
