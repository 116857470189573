#footer {
  border-top: 3px solid #eee;
  margin-top: rem(30px);
  padding-top: rem(40px);

  line-height: 1.8;

  @include media-breakpoint-up(md) {
    padding-top: rem(60px);
    font-size: 0.875rem;
  }

  [class^="col-"] {
    margin-bottom: $spacer;
  }

  b {
    color: $primary;
    font-size: 1rem;
  }

  a {
    color: inherit;
  }

  .logo img {
    width: 180px;
  }

  #footer-top {
    margin-bottom: rem(20px);

    @include media-breakpoint-up(md) {
      margin-bottom: rem(50px);
    }
  }

  #footer-bottom {
    background: #eee;
    padding: rem(15px);

    .container-max-width {
      flex-wrap: wrap;
      display: flex;
      justify-content: space-between;
    }

    .links a {
      margin: 0 5px;
    }

  }
}

