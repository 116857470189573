#product-list {
  text-align: center;
  @include responsive-size($responsive-padding);
  padding-top: 0 !important;

  .product-list-items {
    display: flex;
    text-align: center;
    flex-wrap: wrap;
    justify-content: center;
    margin:rem(40px 0);

    .item {
      border-radius: 10px;
      box-shadow: 0 7px 11px rgba(black, 0.2);
      font-weight: 700;
      font-size: 1rem;
      padding: rem(10px 15px);
      margin: 12px;
      border-bottom: 4px solid $secondary;
      flex-basis: calc(20% - 24px);
      min-width: 135px;
    }
  }

  h3 {
    margin-bottom: $headings-margin-bottom * 3;
  }

  .action-buttons {
    margin-top: $headings-margin-bottom * 3.5;

    a {
      margin: 0 5px;
    }
  }
}
