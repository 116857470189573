#products-slider {
  text-align: center;
  position: relative;
  background: $body-bg;
  padding: rem(38px 0);
  margin: rem(20px 0 70px 0);

  // Shadow
  &::before {
    @include pseudo-content;
    position: absolute;
    background: rgba(black, 0.1);
    border-radius: 50%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    box-shadow: 0 0 50px rgba(black, 0.2);
  }

  h2 {
    margin-bottom: $headings-margin-bottom * 3;
  }

  .swiper-container {
    width: 100%;
    height: 100%;
    padding-left: $spacer;
    padding-right: $spacer;
  }
  .swiper-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: rem(0 20px);

    @include media-breakpoint-up(xl) {
      padding: rem(0 30px);
    }
  }

  .swiper-wrapper {
    align-items: center;
  }

  .product-title {
    margin-top: $headings-margin-bottom;
  }

  .product-link {
    margin: $spacer;
  }
}

.swiper-button-prev,
.swiper-button-next {
  background: none;
  width: 40px;
  height: 40px;

  &:focus,
  &:active,
  &:hover {
    border: 0;
    outline: 0;
  }

  &::before {
    font-family: "Material Icons";
    content: "\e5cb";
    background: none;
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-feature-settings: 'liga';
    font-feature-settings: 'liga';
    font-size: 58px;
    color: $primary;
    position: relative;
    top: -20px;
    left: -10px;
  }
}

.swiper-button-next {
  right: 0;
  &::before {
    content: "\e5cc";
  }
}

.swiper-button-prev {
  left: 0;
}
