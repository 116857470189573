#topbar {
  position: fixed;
  width: 100%;
  z-index: 1337;
  top: 0;

  .container-max-width {
    padding: 0;
  }

  .wrapper {
    border-bottom: 4px solid $secondary;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px $spacer;
    background: white;
  }

  .logo {
    width: 120px;
  }

  nav {
    display: none;
    visibility: hidden;
  }

  .mobile-nav {
    height: 0;
    position: absolute;
    left: 0;
    right: 0;
    background: rgba(white, 1);
    z-index: 1112;
    transition: height 0.3s ease-out;
    overflow: hidden;
    box-shadow: inset 0px 10px 21px -17px;

    &.show {
      height: 100vh;
      overflow-y: scroll;
    }

    ul {
      text-align: left;
      padding: 12px;
      margin-bottom: 0;

      li {
        border-top: 1px solid rgba($secondary, 0.2);

        a {
          display: block;
          color: $body-color;
          padding: 14px 15px;
          font-family: $headings-font-family;
          font-weight: $headings-font-weight;
          font-size: 15px;
        }

        &:first-child {
          border: none;
        }
      }
    }
  }
}

// desktop
@include breakpoint {
  #topbar {

    .wrapper {
      border-bottom: 7px solid $secondary;
      padding: 18px;
      align-items: flex-end;
    }

    .logo {
      width: 200px;
    }

    .mobile-nav,
    .hamburger {
      display: none;
      visibility: hidden;
    }

    nav {
      display: block;
      visibility: visible;
        a {
          color: $body-color;
          font-family: $headings-font-family;
          font-weight: 700;
          margin: 0 5px;
          font-size: rem(16px);
        }
      }
    }
  }

  @include container-breakpoint {
    body {
      padding-top: 0 !important;
    }
  }
