#intro {
  article {
    @include responsive-size($responsive-padding)
  }
  .headline {

  }
}

// News
#news {
  @include responsive-size($responsive-padding);
  padding-top: rem(25px) !important;

  h2 {
    margin-bottom: $headings-margin-bottom * 3;
  }
  article.news-article {
    margin-bottom: $spacer*3;
    .date {
      font-weight: 700;
      font-size: 13px;
      margin-bottom: $spacer / 2;
    }

    .readmore {
      //margin-top: $spacer;
    }
  }
}
